import styled from 'styled-components'
import { Tabs } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

const { TabPane } = Tabs

export const StyledCategoryContent = styled.div`
  padding: 12px;
  overflow: auto;
`

export const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-left: 24px;
`

export const StyledHeaderArrow = styled(LeftOutlined)`
  font-size: 22px;
  color: #0b4793 !important;
`

export const StyledIcon = styled.img`
  margin-left: 16px;
  width: 57px;
`

export const StyledHeaderTitle = styled.h1`
  margin-top: 10px;
  margin-left: 16px;
  font-size: 24px;
`

export const StyledMediaTabs = styled(Tabs)`
  width: 100%;

  .ant-tabs-tab {
    margin-left: 24px;
  }

  .ant-tabs-tab:hover {
    color: #0b4793 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0b4793 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    h1 {
      color: #0b4793;
    }

    .anticon {
      color: #0b4793;
    }
  }

  .ant-tabs-ink-bar {
    background: #0b4793 !important;
  }
`

export const StyledMediaPane = styled(TabPane)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &&& {
    .ant-spin {
      position: absolute;
      top: 50%;
    }
  }
`
export const StyledTabHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;

  .anticon {
    font-size: 32px;
    color: #d2d2d2;
  }
`

export const StyledTabTitle = styled.h1`
  font-size: 16px;
  font-weight: normal;
  color: #d2d2d2;
  margin-top: 10px;
`
